import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Packages from "../components/packages";

import Services from "../components/services";
//import AboutUs from "../components/about-us";
import PortableText from '../components/portableText';
import SEO from '../components/seo';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Instagram from '../components/instagram';
import ClientLinkRepeater from '../components/Clients/clientLinkRepeater'


export const query = graphql`
query HomePageQuery {
  site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    title
    siteSocialMedia {
      facebookUrl
      instagramUrl
      linkedInUrl
      twitterUrl
      youtubeUrl
    }
    openGraph {
      title
      description
    }
  }
  sanityHomePage {
    showTestimonials
    _rawHomepageIntro
    title
    mainImageAlt
    mainImage {
      asset {
        fluid(maxWidth: 1920) {
          ...GatsbySanityImageFluid
        }
      }
    }
    _rawServicesIntro
    _rawPackagesIntro
    _rawNewsletterIntro
    homeServicePanels {
      _key
      pageUrl
      shortDescription
      mainImageAlt
      mainImage {
        asset {
          fluid(maxWidth: 960) {
            ...GatsbySanityImageFluid
          }
        }
      }
      title
    }
  }
}
`;



const IndexPage = ({ data }) => {
  const page = data.sanityHomePage;
  const bannerImgFluid = data.sanityHomePage.mainImage.asset.fluid;
  const {mainImageAlt} = data.sanityHomePage || '';

  return (
    <Layout
      showNav={true}
      showBanner={true}
      bannerImgFluid={bannerImgFluid}
      bannerTitle={page.title}
      newsletterRaw={page._rawNewsletterIntro} contactFormRaw={page._rawContactIntro}
      bannerAlt={mainImageAlt} 
      showTestimonialsSection = {data.sanityHomePage.showTestimonials}
      >
      <SEO
        title={data.site.openGraph.title}
        description={data.site.openGraph.description}
        bodyAttr={{
          class: "",
        }}
      />
      <main className="py-16 md:py-24">
        <div className="container mx-auto text-white">
          <PortableText blocks={page._rawHomepageIntro} />
          <a className="cta" href="#contact" onClick={() => scrollTo('#contactForm')}>Contact Us</a>
        </div>
      </main>
      <Services rawIntro={page._rawServicesIntro} servicePanels={page.homeServicePanels} />
      <Packages rawIntro={page._rawPackagesIntro} />
      {/* <Instagram/> */}
      {/* <ClientLinkRepeater/> */}
    </Layout>
  )
};

export default IndexPage;
