
import React from "react";
import styles from './packages.module.css'
import PortableText from '../components/portableText';
import { PackageRepeater } from '../components/PackageRepeater/packageRepeater';
import scrollTo from 'gatsby-plugin-smoothscroll';
import HeadingTwo from './Headings/headingTwo';
import SectionMain from '../components/Section/SectionMain';

const Packages = ({ rawIntro }) => {

  let wrapperClassName = styles.wrapper += ' py-24';

  return (
    <div className={wrapperClassName}>
      <div className="container mx-auto">
        <HeadingTwo title="Packages"/>
          <SectionMain>
            {rawIntro && 
              <div className="mt-12">
                   <PortableText blocks={rawIntro} />
              </div>
            }
          <div className="mt-12">
            <PackageRepeater />
            <a className="cta cta-black" href="#contact" onClick={() => scrollTo('#contactForm')}>Get a quote</a>
          </div>
          </SectionMain>
      </div>
    </div>
  );
}



export default Packages;
