import React from "react";
import { Link } from 'gatsby';
//import BackgroundImage from 'gatsby-background-image';
import { StyledWrapper} from './serviceRepeaterStyles'
import { GatsbyImage as Img } from "gatsby-plugin-image/compat"

const ServicesRepeater = ({ servicePanels }) => {

  const panelClass = "panel sm:w-1/2 text-white px-5 py-24 sm:py-10 md:px-20 md:py-16 lg:px-24 lg:py-24  xl:px-32 xl:py-32";
  const headingClass = "heading mb-6 md:mb-10 text-3xl sm:text-4xl relative z-10 ";

  return (
    <StyledWrapper>
      {servicePanels.map(s => (
        <Link className={panelClass} key={s._key} to={`/${s.pageUrl}`} aria-label={s.title} title={s.title}>
          <Img
            className="panelBg"
            fluid={s.mainImage.asset.fluid}
          ></Img>
          <h3 className={headingClass}>{s.title}</h3>
          <div className="relative z-10">{s.shortDescription}</div>
          <button className="cta z-10" to={`/${s.pageUrl}`} aria-label={s.title} title={s.title} >MORE INFO</button>
        </Link>
      ))}
    </StyledWrapper>
  );
}

export default ServicesRepeater;
