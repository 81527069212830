import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .gatsby-image-wrapper img[data-main-image]{
     animation: fadeIn 0.25s both;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  .panel {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.4);
      transition: opacity 1.5s ease-out;
      z-index: 1;
    }

    &:hover {
      &:before {
        opacity: 0.5;
      }

      .panelBg {
        transform: scale(1.05);
      }

      .heading::before{
        transform:scale(1);
        opacity:1;
        transform-origin: center left;
      }
    }
  }

  .panelBg {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: transform 1.5s ease-out;
  }

  .heading {
    display: inline-block;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 2px solid #fff;
      left: 0;
      opacity:0;
      transition:transform 0.5s , opacity 0.5s ;
      bottom:0;
      transform:scale(0);
      transform-origin: center right;
    }
  }
`;
