import React from "react";
import { StaticQuery, graphql } from 'gatsby';
import { Package } from './package';
import Slider from 'react-slick';
import {StyledSlider} from './styledSlider';

export const PackageRepeater = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay:true,
    fade:false,
    responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 3
				}
      },
      {
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1
				}
			}
		]
    
  };

  return (
    <StaticQuery
      query={
        graphql`
      query packagesQuery {
        sanityHomePage {
          packages {
            _key
            discount
            discountText
            options
            title
          }
        }
      }
      `
      }
      render={data => (
        <StyledSlider>
        <Slider {...settings}>
          {data.sanityHomePage.packages.map(p => {
            return (
              <Package eventPackage={p} key={p._key} className="slick-slide" />
            )
          })}
        </Slider>
        </StyledSlider>
      )}

    />
  )
};


