
import React from "react";
//import {buildImageObj} from '../lib/helpers'
//import {imageUrlFor} from '../lib/image-url'
import styles from './services.module.css';
import PortableText from '../components/portableText';
import ServicesRepeater from '../components/ServiceRepeater/servicesRepeater';
import HeadingTwo from './Headings/headingTwo';
import SectionMain from '../components/Section/SectionMain';


const Services = ({ rawIntro, servicePanels }) => {

  let wrapperClassName = styles.wrapper += ' ';

  return (
    <section className={wrapperClassName}>
      <div className="container mx-auto ">
        <div className="py-24">
          <HeadingTwo title={"Our Services"} />

          {rawIntro &&
            <SectionMain>
              <PortableText blocks={rawIntro} />
            </SectionMain>
          }

        </div>
      </div>
      <ServicesRepeater servicePanels={servicePanels} />
    </section>
  );
}



export default Services;
