import React from 'react';
import { StyledPackage } from './styledPackage';

export const Package = ({ eventPackage }) => {

    return (
        <StyledPackage  className="bg-gray-900 rounded-md py-5 px-3 mx-2 md:mx-3 text-white" >
            <h3 className="mb-3">
                {eventPackage.title}
            </h3>
            <hr className="mb-3"/>
            <ul className="list flex-grow text-left px-1">
                {eventPackage.options.map(o => (<li>{o}</li>))}
            </ul>
            <hr className="mt-4"/>
            <div className="mt-3">
            <div className="discount text-5xl">{eventPackage.discount}</div>
            <div className="discountText uppercase text-sm">{eventPackage.discountText}</div>
            </div>
         
        </StyledPackage>
    );
};

