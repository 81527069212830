import styled from "styled-components";

export const StyledPackage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .discount {
    font-family: "Cinzel", serif;
    font-weight: 700;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 451px) {
      align-items: flex-start;
      justify-content:flex-start;
    }
  }

  li {
    list-style-type: disc;
    margin-left: 20px;
    text-transform: capitalize;
    &::marker {
      color: #b4914c;
    }
  }
`;
